import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./helpers/scroll-top";
// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));
const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
const HomeFashionSeven = lazy(() => import("./pages/home/HomeFashionSeven"));
const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));
const HomeKidsFashion = lazy(() => import("./pages/home/HomeKidsFashion"));
const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));
const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));
const HomeFurnitureThree = lazy(() =>
  import("./pages/home/HomeFurnitureThree")
);
const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
const HomeFurnitureFive = lazy(() => import("./pages/home/HomeFurnitureFive"));
const HomeFurnitureSix = lazy(() => import("./pages/home/HomeFurnitureSix"));
const HomeFurnitureSeven = lazy(() =>
  import("./pages/home/HomeFurnitureSeven")
);
const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));
const HomeElectronicsTwo = lazy(() =>
  import("./pages/home/HomeElectronicsTwo")
);
const HomeElectronicsThree = lazy(() =>
  import("./pages/home/HomeElectronicsThree")
);
const HomeBookStore = lazy(() => import("./pages/home/HomeBookStore"));
const HomeBookStoreTwo = lazy(() => import("./pages/home/HomeBookStoreTwo"));
const HomePlants = lazy(() => import("./pages/home/HomePlants"));
const HomeFlowerShop = lazy(() => import("./pages/home/HomeFlowerShop"));
const HomeFlowerShopTwo = lazy(() => import("./pages/home/HomeFlowerShopTwo"));
const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));
const HomeOrganicFoodTwo = lazy(() =>
  import("./pages/home/HomeOrganicFoodTwo")
);
const HomeOnepageScroll = lazy(() => import("./pages/home/HomeOnepageScroll"));
const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
const HomeAutoParts = lazy(() => import("./pages/home/HomeAutoParts"));
const HomeCakeShop = lazy(() => import("./pages/home/HomeCakeShop"));
const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
const HomePetFood = lazy(() => import("./pages/home/HomePetFood"));
const HomeMedicalEquipment = lazy(() =>
  import("./pages/home/HomeMedicalEquipment")
);
const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));
const HomeBlackFriday = lazy(() => import("./pages/home/HomeBlackFriday"));
const HomeBlackFridayTwo = lazy(() =>
  import("./pages/home/HomeBlackFridayTwo")
);
const HomeValentinesDay = lazy(() => import("./pages/home/HomeValentinesDay"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

// Delo pages
const SizeGuide = lazy(() => import("./pages/size-guide/SizeGuide"));
const Faq = lazy(() => import("./pages/faq/Faq.js"));

const Orders = lazy(() => import("./pages/account/Orders"));

const Returns = lazy(() => import("./pages/returns/Returns"));

const SupportPolicy = lazy(() =>
  import("./pages/support-policy/SupportPolicy")
);

const Fabrics = lazy(() => import("./pages/fabrics/Fabrics"));

const FabricsSweatshirt = lazy(() =>
  import("./pages/fabrics/FabricsSweatshirt")
);
const FabricsStretchSwim = lazy(() =>
  import("./pages/fabrics/FabricsStretchSwim")
);

const FabricsTshirt = lazy(() => import("./pages/fabrics/FabricsTshirt"));

const FabricsWoven = lazy(() => import("./pages/fabrics/FabricsWoven"));

const FabricsSpecificFabrics = lazy(() =>
  import("./pages/fabrics/FabricsSpecificFabrics")
);

const FurCoatsFabrics = lazy(() => import("./pages/fabrics/FurCoatsFabrics"));

const PatternCutting = lazy(() => import("./pages/services/PatternCutting"));

const Sampling = lazy(() => import("./pages/services/Sampling"));

const Manufacturing = lazy(() => import("./pages/services/Manufacturing"));

function Urls() {
  return (
    <div>
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="Epica-preloader-wrapper">
                <div className="Epica-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path={process.env.PUBLIC_URL + "/"}
                component={About}
              />
              {/* Homepages */}
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion"}
                component={HomeFashion}
              />
              {/*<Route*/}
              {/*  path={process.env.PUBLIC_URL + "/home-fashion-two"}*/}
              {/*  component={HomeFashionTwo}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={process.env.PUBLIC_URL + "/home-fashion-three"}*/}
              {/*  component={HomeFashionThree}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={process.env.PUBLIC_URL + "/home-fashion-four"}*/}
              {/*  component={HomeFashionFour}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={process.env.PUBLIC_URL + "/home-fashion-five"}*/}
              {/*  component={HomeFashionFive}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={process.env.PUBLIC_URL + "/home-fashion-six"}*/}
              {/*  component={HomeFashionSix}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={process.env.PUBLIC_URL + "/home-fashion-seven"}*/}
              {/*  component={HomeFashionSeven}*/}
              {/*/>*/}
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-eight"}
                component={HomeFashionEight}
              />
              <Route
                path={process.env.PUBLIC_URL + "/services/pattern-cutting"}
                component={PatternCutting}
              />
              <Route
                path={process.env.PUBLIC_URL + "/services/sampling"}
                component={Sampling}
              />
              <Route
                path={process.env.PUBLIC_URL + "/services/manufacturing"}
                component={Manufacturing}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/fabrics"}
                component={Fabrics}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/fabrics/sweatshirt"}
                component={FabricsSweatshirt}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/fabrics/stretch-swim"}
                component={FabricsStretchSwim}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/fabrics/t-shirt"}
                component={FabricsTshirt}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/fabrics/woven"}
                component={FabricsWoven}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/fabrics/specific-fabrics"}
                component={FabricsSpecificFabrics}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/fabrics/fur-coats"}
                component={FurCoatsFabrics}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                render={(routeProps) => (
                  <Product {...routeProps} key={routeProps.match.params.id} />
                )}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                component={ProductTabLeft}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                component={ProductTabRight}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                component={ProductSticky}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-slider/:id"}
                component={ProductSlider}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                component={ProductFixedImage}
              />
              {/* Blog pages */}
              <Route
                path={process.env.PUBLIC_URL + "/blog-standard"}
                component={BlogStandard}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                component={BlogNoSidebar}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                component={BlogRightSidebar}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-details-standard"}
                component={BlogDetailsStandard}
              />
              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                component={About}
              />
              <Route
                path={process.env.PUBLIC_URL + "/size-guide"}
                component={SizeGuide}
              />
              <Route path={process.env.PUBLIC_URL + "/faq"} component={Faq} />
              <Route
                path={process.env.PUBLIC_URL + "/returns"}
                component={Returns}
              />
              <Route
                path={process.env.PUBLIC_URL + "/support-policy"}
                component={SupportPolicy}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                component={Contact}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                component={MyAccount}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                component={LoginRegister}
              />
              <Route path={process.env.PUBLIC_URL + "/cart"} component={Cart} />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                component={Wishlist}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                component={Compare}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                component={Checkout}
              />
              <Route
                path={process.env.PUBLIC_URL + "/not-found"}
                component={NotFound}
              />{" "}
              <Route
                path={process.env.PUBLIC_URL + "/orders"}
                component={Orders}
              />
              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default Urls;
